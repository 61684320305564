import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import MainSection from "../components/careersSections/mainSection/mainSection";
import PositionsSection from "../components/careersSections/positionsSection/positionsSection";
import BlueSection from "../components/blueSetion/blueSection";

const CareerPage = () => {
  const textArr = ["Move money", " fast, ", "without risk."];
  return (
    <Layout>
      <Seo title="Company" pathname="/company" />
      <main>
        <MainSection />
        <PositionsSection />
        <BlueSection textArr={textArr} btnText={"Book a demo"} />
      </main>
    </Layout>
  );
};

export default CareerPage;
