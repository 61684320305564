import { css } from "@emotion/react";
import React, { FC } from "react";
import upArrow from "../../images/dropdown_up_black.svg";

interface Props {
  title?: string;
  location?: string;
  isRemote?: boolean;
  jobUrl?: string;
}
const Position: FC<Props> = ({ title, location, isRemote, jobUrl }) => {
  return (
    <div
      className="w-full rounded-lg bg-grey-transparent border-0 my-1.5 hover:bg-grey-career"
      css={css`
        min-height: 84px;
        max-width: 609px;
        margin: 0 auto;
      `}
    >
      <a
        href={jobUrl}
        target="_blank"
        className="relative w-full h-full flex flex-row justify-start py-4 px-2"
      >
        <div className="flex flex-col flex-1">
          <p className="font-Inter text-base font-medium text-blue-accent mb-1">
            {title}
          </p>
          <p className="font-Inter text-sm font-normal text-blue-darken flex">
            {location}

            {isRemote ? (
              <>
                <span
                  className="text-blue-darken mt-0.5 mx-1"
                  css={css`
                    font-size: 5px;
                  `}
                >
                  &#9679;
                </span>{" "}
                Remote
              </>
            ) : (
              ""
            )}
          </p>
        </div>
        <div className="flex flex-row justify-start">
          <p className="font-Inter text-sm font-semibold pt-0.5">Apply</p>
          <img
            className="h-1.5 ml-2.5 mt-2 mr-3"
            css={css`
              transform: rotateZ(90deg);
            `}
            width={8}
            height={5}
            src={upArrow}
            alt="arrow"
          />
        </div>
      </a>
    </div>
  );
};

export default Position;
