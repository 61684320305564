import React, { FC } from "react";
import { MainSectionWrapper, LogoSectionWrapper } from "./mainSection.styles";
import career from "../../../images/career.svg";
import { navigate } from "gatsby";
import bolt from "../../../images/careers/logos/Bolt.png";
import google from "../../../images/careers/logos/Google.png";
import zelle from "../../../images/careers/logos/Zelle.png";
import hsbc from "../../../images/careers/logos/hsbc.png";
import tdbank from "../../../images/careers/logos/tdbank.png";
import revolut from "../../../images/careers/logos/Revolut.png";
import adyen from "../../../images/careers/logos/adyen.png";
import paypal from "../../../images/careers/logos/paypal.png";
import uber from "../../../images/careers/logos/uber.png";
import riskified from "../../../images/careers/logos/Riskified.png";
import amazon from "../../../images/careers/logos/amazon.png";
import stripe from "../../../images/careers/logos/stripe.png";
import github from "../../../images/careers/logos/github.png";

interface Props {
  //   title: string;
}
const MainSection: FC<Props> = () => {

  const logos = [
    revolut, paypal, bolt, google, uber, stripe,
    adyen, riskified, amazon, github, tdbank, hsbc, zelle
  ];

  return (
    <section className="section-wrapper relative" css={MainSectionWrapper}>
      <div className="curves-parent absolute top-0 left-full"></div>
      <div className="wrapper-parent layout-width bg-transparent relative w-full pt-24">
        <div className="flex flex-row justify-between w-full md:flex-col">
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-center mb-20 md:mb-0">
              <p className="font-Inter font-semibold text-blue-accent text-lg content-start mb-5 md:text-base md:mb-6 md:text-center">
                Work with us
              </p>
              <p className="font-Inter title-parent text-blue-darken tracking-tight text-5xl font-semibold mb-4 md:text-4xl md:text-center  md:mb-6 md:mr-auto md:ml-auto">
                Join us in shaping the future of global finance
              </p>
              <p className="font-Inter subtitle-parent text-blue-darken text-lg font-normal mb-8 md:text-center md:text-base md:font-normal md:mb-10 md:mr-auto md:ml-auto">
                At Sardine, we’re on the lookout for passionate and inspired
                people looking to do meaningful work and make a positive impact
                on the financial services industry. Join our team of expert
                fraud fighters from Coinbase, Revolut, Zelle, Paypal, and Bolt.
              </p>
              <button
                className="font-Inter btn-parent bg-blue-accent h-12 text-white text-base font-semibold ml-0 px-8 rounded-2xl md:hidden hover:bg-blue-hover"
                onClick={() =>
                  navigate("#positions", { state: { source: "open_roles" } })
                }
              >
                Open Roles
              </button>
            </div>
          </div>
          <div className="image-parent flex flex-col justify-center mx-auto">
            <img src={career} alt="developers" />
          </div>
        </div>
        <LogoSectionWrapper>
        {
          logos.map((logo, index) => (
              <img
              className="auto-img mx-4 pb-4"
              src={logo}
              alt={`logo_${index}`}
            />
          ))
        }
        </LogoSectionWrapper>
      </div>
    </section>
  );
};

export default MainSection;
