import React, { FC, useState, Fragment, useEffect } from "react";
import axios from "axios";
import Position from "../../position/position";
import { css } from "@emotion/react";

interface Props {
  //   title: string;
}
type apiData = {
  apiVersion: string;
  jobs: [
    {
      address?: string;
      applyUrl?: string;
      department?: string;
      descriptionHtml?: string;
      descriptionPlain?: string;
      id?: string;
      isListed?: boolean;
      isRemote?: boolean;
      jobUrl?: string;
      location?: string;
      publishedAt?: string;
      title?: string;
    }
  ];
};
const PositionsSection: FC<Props> = () => {
  const [positions, setPositions] = useState([] as apiData["jobs"] | []);
  useEffect(() => {
    axios
      .get("https://api.ashbyhq.com/posting-api/job-board/sardine")
      .then((res) => setPositions((res.data as apiData).jobs))
      .catch((err) => console.error(err));
  }, []);
  const departments = [
    { title: "Engineering", count: 0 },
    { title: "Product", count: 0 },
    { title: "Payment", count: 0 },
    { title: "Security", count: 0 },
    { title: "Customer Success", count: 0 },
    { title: "Customer Support", count: 0 },
    { title: "Partnerships", count: 0 },
    { title: "Sales & BD", count: 0 },
    { title: "Legal & Finance", count: 0 },
    { title: "Human Capital", count: 0 },
    { title: "Operations", count: 0 },
    { title: "Marketing", count: 0 },
  ];
  return (
    <section className="section-wrapper relative" id="positions">
      <div className="curves-parent absolute top-0 left-full"></div>
      <div className="wrapper-parent layout-width bg-transparent relative w-full pt-24">
        <div className="flex flex-col justify-between w-full">
          <p className="font-Inter font-semibold text-lg text-blue-accent mb-4 text-center tracking-tighter">
            Join us!
          </p>
          <p className="font-Inter font-semibold text-3xl text-blue-darken mb-16 text-center tracking-tighter">
            Open positions
          </p>
          {departments.map((department, i) => (
            <div key={i} className="engineering mb-11">
              {positions.map((category, index) => {
                if (category.department?.toLowerCase() === department.title.toLowerCase())
                  department.count += 1;
                return (
                  <Fragment key={index}>
                    {category.department?.toLowerCase() === department.title.toLowerCase() ? (
                      <>
                        {department.count === 1 ? (
                          <p
                            className="font-Inter font-semibold text-lg text-blue-darken mx-auto mb-4"
                            css={css`
                              max-width: 609px;
                              padding-left: 8px;
                            `}
                          >
                            {department.title}
                          </p>
                        ) : null}
                        <Position
                          title={category.title}
                          location={category.location}
                          isRemote={category.isRemote}
                          jobUrl={category.jobUrl}
                        />
                      </>
                    ) : null}
                  </Fragment>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PositionsSection;
